@charset "utf-8";

div {
  &.nav-item {
    padding: 0 7px;
  }
}

.container {
  &.is-header {
    margin: 10px 0;
    max-width: 75%
  }
  &.is-content {
    &.title {
      font-size: 30px;
    }
  }
  &.content {
    position: relative;
    top: 0px;
  }
}

.logo {
  width: 15%;
  margin-right: 30px
}

.profile {
  height: 41px;
}

.level {
  &.content-right {
    justify-content: flex-end;
  }
  &.content-left {
    justify-content: flex-start;
  }
  &.content-center {
    justify-content: center;
  }
}

.button {
  &.is-orange {
    border-color: #ED7D31;
    color: #ED7D31;
    &.is-selected {
      color: #FFFFFF;
      background-color: #ED7D31;
    }
  }

  &.is-danger {
    background-color: #f04d68;
    font-weight: 500;
  }
  &.has-space {
    margin: 20px 0;
    &.bot {
      margin-top: 0;
    }
  }
  .p {
    justify-content: flex-end;
  }
}

.icon {
  &.is-orange {
    color: #FA7D61 !important;
  }
}

.hero {
  .hero-body {
    .container {
      .subtitle {
        &.is-gray {
          color: #95989F;
        }
      }
    }
  }
}





@import "../node_modules/bulma/bulma.sass"
